


















































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
      },
      posts: [],
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      const res = await this.$http.request({
        url: 'posts',
        method: 'get',
        params: this.paginate,
      })
      this.posts = res.data.data
      this.paginate.total = res.data.meta.total
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.fetch()
    },
    handleSizeChange(size: number) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page: number) {
      this.paginate.page = page
      this.fetch()
    },
    handleRemove(row: any) {
      this.$confirm(`此操作将永久删除"${row.title}", 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`posts/${row.id}`)
        this.$message.success('删除成功')
        this.fetch()
      })
    },
  },
})
